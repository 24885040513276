export enum SalesActionTypes {
  API_RESPONSE_SUCCESS = "@@sales/API_RESPONSE_SUCCESS",
  API_RESPONSE_ERROR = "@@sales/API_RESPONSE_ERROR",
  GET_PROJECTS = "@@sales/GET_PROJECTS",
  GET_PROJECTBYID = "@@sales/GET_PROJECTSBYID",

  GET_CLIENT = "@@sales/GET_CLIENTS",
  GET_CLIENT_ID = "@@sales/GET_CLIENTS_ID",
  DELETE_CLIENT = "@@sales/DELETE_CLIENTS",
  UPDATE_CLIENT = "@@sales/UPDATE_CLIENTS",
  CREATE_CLIENT = "@@sales/CREATE_CLIENTS",

  GET_CLIENT_CONTACT = "@@sales/GET_CLIENTS_CONTACT",
  DELETE_CLIENT_CONTACT = "@@sales/DELETE_CLIENTS_CONTACT",
  UPDATE_CLIENT_CONTACT = "@@sales/UPDATE_CLIENTS_CONTACT",
  CREATE_CLIENT_CONTACT = "@@sales/CREATE_CLIENTS_CONTACT",

  GET_CLIENT_ADDRESS = "@@sales/GET_CLIENTS_ADDRESS",
  DELETE_CLIENT_ADDRESS = "@@sales/DELETE_CLIENTS_ADDRESS",
  UPDATE_CLIENT_ADDRESS = "@@sales/UPDATE_CLIENTS_ADDRESS",
  CREATE_CLIENT_ADDRESS = "@@sales/CREATE_CLIENTS_ADDRESS",

  GET_CLIENT_TASKS = "@@sales/GET_CLIENTS_TASKS",
  DELETE_CLIENT_TASKS = "@@sales/DELETE_CLIENTS_TASKS",
  UPDATE_CLIENT_TASKS = "@@sales/UPDATE_CLIENTS_TASKS",
  CREATE_CLIENT_TASKS = "@@sales/CREATE_CLIENTS_TASKS",

  GET_DOCUMENTS = "@@sales/GET_DOCUMENTS",
  UPLOAD_DOCUMENTS = "@@sales/UPLOAD_DOCUMENTS",
  DELETE_DOCUMENT = "@@sales/DELETE_DOCUMENT",
  GET_CATEGORY = "@@sales/GET_CATEGORY",
  GET_USERS = "@@sales/GET_USERS",
  UPDATE_PROJECT = "@@sales/UPDATE_PROJECT",
  CREATE_PROJECT = "@@sales/CREATE_PROJECT",
  DELETE_PROJECT = "@@sales/DELETE_PROJECT",

  GET_TECH_PRODUCTS = "@@sales/GET_TECH_PRODUCTS",
  ADD_TECH_QUOTE = "@@sales/ADD_TECH_QUOTE",
  UPDATE_TECH_QUOTE = "@@sales/UPDATE_TECH_QUOTE",
  GET_TECH_QUOTES = "@@sales/GET_TECH_QUOTES",
  SEND_FOR_APPROVAL = "@@sales/SEND_FOR_APPROVAL",
  GET_TECH_QUOTE_BY_ID = "@@sales/GET_TECH_QUOTE_BY_ID",
  GET_TECH_QUOTE_BY_CLIENT = "@@sales/GET_TECH_QUOTE_BY_CLIENT",
  DELETE_TECH_QUOTE_BY_ID = "@@sales/DELETE_TECH_QUOTE_BY_ID",
  DUPLICATE_TECH_QUOTE = "@@sales/DUPLICATE_TECH_QUOTE",
  ADD_DIGITAL_QUOTE = "@@sales/ADD_DIGITAL_QUOTE",
  UPDATE_DIGITAL_QUOTE = "@@sales/UPDATE_DIGITAL_QUOTE",
  GET_DIGITAL_QUOTES = "@@sales/GET_DIGITAL_QUOTES",
  GET_DIGITAL_QUOTE_BY_ID = "@@sales/GET_DIGITAL_QUOTE_BY_ID",
  DELETE_DIGITAL_QUOTE_BY_ID = "@@sales/DELETE_DIGITAL_QUOTE_BY_ID",
  DUPLICATE_DIGITAL_QUOTE = "@@sales/DUPLICATE_DIGITAL_QUOTE",
  GET_RATE_CARDS = "@@sales/GET_RATE_CARDS",
  GET_RATE_CARD_BY_ID = "@@sales/GET_RATE_CARD_BY_ID",
  ADD_RATE_CARDS = "@@sales/ADD_RATE_CARDS",
  UPDATE_RATE_CARDS = "@@sales/UPDATE_RATE_CARDS",
  DELETE_RATE_CARDS = "@@sales/DELETE_RATE_CARDS",
  // SET_TECH_QUOTE_DEFAULT = '@@sales/SET_TECH_QUOTE_DEFAULT'
  GET_CONSUMABLES = "@@sales/GET_CONSUMABLES",
  ADD_CONSUMABLES = "@@sales/ADD_CONSUMABLES",
  UPDATE_CONSUMABLES = "@@sales/UPDATE_CONSUMABLES",
  DELETE_CONSUMABLES = "@@sales/DELETE_CONSUMABLES",
  GET_SUPPLIERS = "@@sales/GET_SUPPLIERS",
  GET_DASH_CONSUMABLES = "@@sales/GET_DASH_CONSUMABLES",
}
